import { useGetUserProfile } from '~/domains/users/queries'

export default defineNuxtRouteMiddleware(async () => {
  const { data: user, suspense } = useGetUserProfile()

  await suspense()

  if (user.value!.role !== 'super_admin') {
    return abortNavigation('Forbidden')
  }
})
